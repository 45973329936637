import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './empty.component.html',
  styleUrl: './empty.component.scss'
})
export class EmptyComponent implements OnInit {
  @Input() variant: 'IMAGE' | 'ICON' = 'IMAGE';
  @Input() icon = '';
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' = 'sm';
  @Input() background: 'empty' | 'notFound' = 'empty';
  @Input() title = '';
  @Input() titleClass = '';
  @Input() styleClass = '';
  @Input() description = '';

  src = '';

  ngOnInit() {
    switch (this.background) {
      case 'empty': {
        this.src = '/assets/images/Empty.svg';
        break;
      }
      case 'notFound': {
        this.src = '/assets/images/NotFound.svg';
        break;
      }
      default: {
        this.src = '/assets/images/Empty.svg';
      }
    }
  }
}
