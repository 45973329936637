@if (variant === 'IMAGE') {
  <div [ngClass]="styleClass" class="flex flex-col gap-6 items-center justify-center min-h-[430px]">
    <img
      [ngClass]="{
        'w-20 h-20': size == 'xs',
        'w-[120px] h-[120px]': size == 'sm',
        'w-[180px] h-[180px]': size == 'md',
        'w-[240px] h-[240px]': size == 'lg'
      }"
      src="{{ src }}"
      alt="Empty" />
    <div class="flex flex-col gap-2 items-center">
      <div [ngClass]="titleClass ? titleClass : 'text-xl font-semibold'">{{ title }}</div>
      <div class="text-gray-500">{{ description }}</div>
    </div>

    <ng-content select="[footer]"></ng-content>
  </div>
} @else {
  <div [ngClass]="[styleClass, 'flex flex-col gap-3 py-10 items-center']">
    <div class="h-11 w-11 flex items-center justify-center bg-neutral-alpha-200A rounded-full">
      <i [class]="icon + ' text-7'"></i>
    </div>
    <div>{{ description }}</div>
  </div>
}
